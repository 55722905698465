<template>
  <div v-if="expanded">
    <div
      class="airport-picker__modal__caret inset-x-1/2 absolute bg-white border-t border-l transform rotate-45 -translate-y-1/2 border-gray-200 z-40 top-[173%]"
    />
    <div
      class="time-picker-clock absolute w-64 bg-white font-sans text-basenf font-bold z-40 rounded shadow-dropdown top-[172%]"
      @click.stop
    >
      <div class="time flex justify-around w-full py-3 capitalize">
        <div
          class="text-gray text-gray-400 flex-1 w-1/2 text-center leading-none text-mdnf"
        >
          <span>{{ $i18n('reservation.hours') }}</span>
        </div>
        <div
          class="text-gray text-gray-400 flex-1 w-1/2 text-center leading-none text-mdnf"
        >
          <span>{{ $i18n('general.minutes') }}</span>
        </div>
      </div>
      <div class="flex w-full">
        <InfiniteColumn
          ref="hours"
          :items="hours"
          :preselected-item="adaptedHours"
          class="time-picker-clock__hours overflwo-y-scroll w-full"
          @selectionChanged="updateTime('hours', $event)"
        />
        <InfiniteColumn
          ref="minutes"
          :items="minutes"
          :preselected-item="time.minutes"
          class="time-picker-clock__minutes place-content-start w-full"
          @selectionChanged="updateTime('minutes', $event)"
        />
      </div>
      <div class="mx-5 whitespace-normal">
        <div
          v-if="!isEndTime"
          class="text-[#3D3D3D] text-mdnf font-sans text-wrap font-normal mt-[18px] mb-2.5 leading-normal"
        >
          {{ $i18n('search.arrival-time-explanation-general') }}
        </div>
        <div
          v-else
          class="text-[#3D3D3D] text-mdnf font-sans text-wrap font-normal mt-[18px] mb-2.5 leading-normal"
        >
          {{ $i18n('search.departure-time-explanation-general') }}
        </div>
        <div class="flex justify-between items-center mt-2.5 mb-5 capitalize">
          <button
            class="text-mdnf font-sans font-bold text-blue-600"
            @click.stop.passive="collapse"
          >
            {{ $i18n('templates.cancel') }}
          </button>
          <button
            class="text-basenf font-sans font-bold text-white bg-orange-500 leading-none px-5 py-2 rounded-sm"
            @click.stop.passive="selectTime"
          >
            {{ $i18n('general.oke') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteColumn from './InfiniteColumn.vue';

export default {
  components: {
    InfiniteColumn,
  },
  props: {
    expanded: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    selectedValue: {
      type: Array,
      required: false,
      default: () => [],
    },
    isEndTime: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      time: {
        hours: this.selectedValue?.[0],
        minutes: this.selectedValue?.[1],
      },
      fristLoad: true,
    };
  },
  computed: {
    hours() {
      const hours = [];
      for (let i = 0; i < 24; i += 1) {
        hours.push(i.toString());
      }
      return hours;
    },
    minutes() {
      return ['00', '15', '30', '45'];
    },
    adaptedHours() {
      const hours = +this.time.hours;

      return hours.toString();
    },
    formattedTime() {
      return `${this.time.hours}:${this.time.minutes}`;
    },
  },
  watch: {
    expanded: {
      handler(newValue) {
        // Keep the value as it is if there is not a new selection
        if (!newValue) {
          this.time = {
            hours: this.selectedValue?.[0],
            minutes: this.selectedValue?.[1],
          };
        }
      },
    },
  },
  methods: {
    updateTime(key, value) {
      this.time[key] = value;
    },
    selectTime(expandNextPicker = true) {
      const time = `${this.time.hours}:${this.time.minutes}`;
      this.$emit('timeChanged', { time, expandNextPicker });
    },
    collapse() {
      if (this.expanded) this.$emit('collapsed');
    },
  },
};
</script>

<style lang="scss">
.airport-picker {
  &__modal {
    @apply h-full md:h-auto;
    &__caret {
      left: 21px;
    }
  }
}
</style>
