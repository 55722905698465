<template>
  <div
    v-click-outside="hide"
    role="menu"
    tabindex="0"
    class="cursor-pointer relative"
    @focusout.stop.passive="hide"
    @mouseleave="hide"
  >
    <div
      role="menu"
      tabindex="0"
      @focusin.stop.passive="toggle"
      @mouseenter="toggle"
      @click="toggle"
    >
      <IconTooltip />
    </div>

    <div v-show="open" class="relative z-50">
      <div class="tool-tip-picker__modal__caret top-0 right-0  transform rotate-45 absolute  bg-white  shadow-dropdown " />
      <div
        :class="{'-left-[50px]':left}"
        class="absolute right-0 shadow-dropdown bg-white p-4 -right-8 top-full w-56 max-w-xs  md:w-max translate-y-2 transform"
      >
        <div class="flex justify-center">
          <slot />
          <span
            role="button"
            tabindex="0"
            class="text-gray-400 sm:hidden block"
            @keypress="hide"
            @click="hide"
          >
            X
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTooltip from '~/components/icons/IconTooltip.vue';
import ClickOutside from 'vue-click-outside';

export default {
  directives: {
    clickOutside: ClickOutside,
  },
  components: {
    IconTooltip,
  },
  props: {
    left: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    hide() {
      this.open = false;
    },
  },
};
</script>

<style lang="scss">
  .tool-tip-picker {
    &__modal {
      @apply h-full xs:h-auto;
      &__caret {
        width: 14px;
        height: 14px;
        right: 10%;
      }
    }
  }
</style>
