import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

const dateQueryKey = 'query';

const defaultQuery = {
  arrival: dayjs(new Date()).add(7, 'days').toDate(),
  departure: dayjs(new Date()).add(14, 'days').toDate(),
  arrivalTime: '12:00',
  departureTime: '12:00',
  sort_f: 'price',
  sort_w: 'asc',
  version: 5,
};

/**
 * @param location {string}
 * */
export const getQueryFromLocalStorage = (location) => {
  try {
    if (process.client && localStorage) {
      const query = localStorage.getItem(dateQueryKey)
        ? JSON.parse(localStorage.getItem(dateQueryKey))
        : null;

      if (query) {
        if (
          dayjs(query.arrival)?.isSameOrAfter(new Date()) &&
          dayjs(query.departure)?.isSameOrAfter(query.arrival)
        ) {
          return {
            ...query,
            arrival: dayjs(query.arrival).toDate(),
            departure: dayjs(query.departure).toDate(),
          };
        }
      }

      localStorage.setItem(
        dateQueryKey,
        JSON.stringify({
          ...defaultQuery,
          location,
          arrival: dayjs(defaultQuery.arrival).format('YYYY-MM-DD'),
          departure: dayjs(defaultQuery.departure).format('YYYY-MM-DD'),
        })
      );
    }
  } catch (error) {
    console.log(error);
  }

  return defaultQuery;
};

export const setQueryInLocalStorage = (query) => {
  try {
    if (typeof window !== 'undefined' && localStorage) {
      localStorage.setItem(
        dateQueryKey,
        JSON.stringify({ ...defaultQuery, ...query })
      );
    }
  } catch (error) {
    console.log(error);
  }
};
