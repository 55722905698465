<template>
  <!-- Main container -->
  <div class="relative" @click.stop>
    <!-- Calendar toggle button -->
    <slot
      name="date"
      :toggle="toggleCalendar"
      :is-expanded="isExpanded"
      :is-rendered="isRendered"
    />

    <component
      :is="$isMobile ? 'Mobile' : 'Desktop'"
      v-if="isExpanded"
      :show-calendar="isExpanded && isRendered"
      :min-date="minDate"
      :default-time="defaultTime"
      :date="date"
      :date-other="dateOther"
      @selectDate="selectDate"
      @collapseDate="collapseDate"
      @hook:beforeMount="componentLoaded"
    />
  </div>
</template>

<script>
export default {
  components: {
    Desktop: () => import('./paDesktopCalendar.vue'),
    Mobile: () => import('./paMobileCalendar.vue'),
  },
  props: ['minDate', 'defaultTime', 'date', 'dateOther', 'isEndDate'],
  data() {
    return {
      isExpanded: false,
      isRendered: false,
    };
  },
  mounted() {
    document.addEventListener('click', this.collapseDate);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.collapseDate);
  },
  methods: {
    collapseDate() {
      this.isExpanded = false;
      this.$emit('collapsed', this.isExpanded);
    },
    expand() {
      this.isExpanded = true;
      this.$emit('expanded');
    },
    toggleCalendar() {
      this.isExpanded = !this.isExpanded;
      if (this.isExpanded) {
        this.$emit('expanded');
      } else {
        this.$emit('collapsed', this.isExpanded);
      }
    },
    selectDate(selectedDate) {
      this.isExpanded = false;
      this.$emit('collapsed', this.isExpanded);
      this.$emit('dateChanged', selectedDate);
      this.$store.commit('bucket/update', {
        key: 'pickerInteracted',
        value: this.isEndDate ? 'departureDate' : 'arrivalDate',
      });
    },
    componentLoaded() {
      if (!this.isRendered) {
        // eslint-disable-next-line no-return-assign
        this.$nextTick(() => (this.isRendered = true));
      }
    },
  },
};
</script>
