<template>
  <div v-if="expanded">
    <div
      class="airport-picker__modal__caret inset-x-1/2 absolute bg-white border-t border-l transform rotate-45 -translate-y-1/2 border-gray-200 z-40 top-[176%]"
    />
    <div
      class="time-picker-clock absolute bg-white font-sans text-basenf font-bold z-40 rounded shadow-dropdown top-[175%]"
      @click.stop
    >
      <div
        class="time flex justify-around w-full text-center font-normal text-mdnf capitalize divide-x divide-athens-500"
      >
        <div
          class="text-gray text-gray-400 flex-1 w-1/2 text-center text-mdnf py-2.5"
        >
          <span>{{ $i18n('reservation.hours') }}</span>
        </div>
        <div
          class="text-gray text-gray-400 flex-1 w-1/2 text-center text-mdnf py-2.5"
        >
          <span>{{ $i18n('general.minutes') }}</span>
        </div>
      </div>
      <div class="flex w-full h-52 divide-x border-b border-athens-500 divide-athens-500">
        <InfiniteColumn
          ref="hours"
          :items="hours"
          :preselected-item="adaptedHours"
          class="flex-1"
          @click.stop.passive="clear(['minutes', 'meridiem'])"
          @selectionChanged="updateTime('hours', $event)"
        />
        <InfiniteColumn
          ref="minutes"
          :items="minutes"
          :preselected-item="time.minutes"
          class="place-content-start flex-1"
          @click.stop.passive="clear(['hours', 'meridiem'])"
          @selectionChanged="updateTime('minutes', $event)"
        />
      </div>
      <div class="flex justify-end items-center my-2.5">
        <button
          class="text-basenf font-sans font-bold text-white bg-orange-500 leading-none px-5 py-2 mr-[19px] rounded-sm"
          @click.stop.passive="selectTime"
        >
          {{ $i18n('general.oke') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteColumn from './InfiniteColumn.vue';

export default {
  components: {
    InfiniteColumn,
  },
  props: {
    expanded: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    selectedValue: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      time: {
        hours: this.selectedValue?.[0],
        minutes: this.selectedValue?.[1],
      },
      fristLoad: true,
    };
  },
  computed: {
    hours() {
      const hours = [];
      for (let i = 0; i < 24; i += 1) {
        hours.push(i.toString());
      }
      return hours;
    },
    minutes() {
      return ['00', '15', '30', '45'];
    },
    adaptedHours() {
      const hours = +this.time.hours;
      return hours.toString();
    },
    formattedTime() {
      return `${this.time.hours}:${this.time.minutes}`;
    },
  },
  watch: {
    expanded: {
      handler(newValue) {
        // Keep the value as it is if there is not a new selection
        if (!newValue) {
          this.time = {
            hours: this.selectedValue?.[0],
            minutes: this.selectedValue?.[1],
          };
        }
      },
    },
  },
  methods: {
    clear(keyArray) {
      keyArray.forEach((key) => {
        this.$refs[key].clear();
      });
    },
    updateTime(key, value) {
      this.time[key] = value;
    },
    selectTime(expandNextPicker = true) {
      const time = `${this.time.hours}:${this.time.minutes}`;
      this.$emit('timeChanged', { time, expandNextPicker });
    },
    collapse() {
      if (this.expanded) this.$emit('collapsed');
    },
  },
};
</script>

<style lang="scss">
.airport-picker {
  &__modal {
    @apply h-full md:h-auto;
    &__caret {
      left: 18px;
    }
  }
}
</style>
