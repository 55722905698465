<template>
  <div
    v-click-outside="handleClickOutside"
    role="button"
    tabindex="0"
    class="legacy-time-picker relative"
    @keypress.stop.prevent="toggle"
    @click.stop.prevent="toggle"
    @keydown.enter.prevent="toggle"
  >
    <client-only>
      <slot name="header" />

      <div
        v-if="isReady"
        class="md:flex items-center hidden font-sans font-normal"
      >
        {{ timeFormatted }}
        <CaretTransition :show="show" class="legacy-time-picker__caret" />
      </div>
      <TimePicker
        ref="timePicker"
        :selected-value="currentValue"
        :expanded="show"
        :is-end-time="isEndTime"
        @timeChanged="timeChanged"
        @collapsed="collapse"
      />
    </client-only>
  </div>
</template>

<script>
import CaretTransition from '~/components/CaretTransition.vue';
import ClickOutside from 'vue-click-outside';
import TimePicker from '~/components/experiments/tr-934-time-picker-desktop/c-variant/MobileTimePicker/TimePicker.vue';

export default {
  directives: {
    clickOutside: ClickOutside,
  },
  components: {
    CaretTransition,
    TimePicker,
  },
  props: {
    isEndTime: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    value: {
      type: String,
      required: false,
      default: () => '12:00',
    },
  },
  data() {
    return {
      show: false,
      isReady: false,
    };
  },
  computed: {
    isExpanded() {
      return this.show;
    },
    language() {
      return this.$store.state.language;
    },
    languages() {
      return this.$store.state.languages;
    },
    timeFormatted() {
      if (!this.value) {
        return '12:00';
      }
      const [hours, minutes] = this.value.split(':');

      return `${hours}:${minutes}`;
    },
    currentValue() {
      const formattedTime = this.timeFormatted;
      return formattedTime.split(':');
    },
  },
  mounted() {
    this.isReady = true;
  },
  methods: {
    timeChanged(providedValue) {
      this.$store.commit('bucket/update', {
        key: 'pickerInteracted',
        value: this.isEndTime ? 'timeEnd' : 'timeStart',
      });

      this.$emit('timeChanged', providedValue?.time || this.$refs.picker.value);
      this.collapse();
      if (providedValue.expandNextPicker) this.$emit('expandNextPicker');
    },
    toggle() {
      if (this.$isMobile) {
        return;
      }
      if (this.show) {
        this.collapse();
      } else {
        this.expand();
      }
    },
    expand() {
      this.show = true;
      this.$emit('expanded');
    },
    collapse() {
      this.show = false;
      this.$emit('collapsed');
    },
    handleClickOutside() {
      if (this.isExpanded) {
        if (this.$refs.timePicker.formattedTime !== this.timeFormatted) {
          this.$refs.timePicker.selectTime();
        }
        this.collapse();
      }
    },
  },
};
</script>

<style lang="scss">
.legacy-time-picker {
  @apply bg-white flex-col relative cursor-pointer whitespace-nowrap;
  $self: &;

  &:hover {
    #{$self}__caret {
      @apply text-orange-500;
    }
  }

  &__caret {
    @apply absolute md:static bottom-3 right-0 text-gray-400 -translate-x-1/2 md:translate-x-0 pointer-events-none md:pointer-events-auto md:ml-2;
  }

  &__select {
    @apply bg-white appearance-none bg-transparent pr-6;
    outline: none !important;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 3px;
      @apply bg-silver-100;
    }

    &::-webkit-scrollbar-thumb {
      @apply bg-black-alt-300 rounded-md;
    }

    option {
      @apply font-bold bg-white p-1 flex items-center;

      &:hover,
      &:checked {
        @apply bg-orange-500 cursor-pointer text-white;
      }
    }
  }
}
</style>
