<template>
  <div
    class="time-picker-column border border-athens-500 border-l-0 flex justify-items-center flex-col overflow-hidden pt-4.5 pt-[18px] snap-proximity snap-y"
  >
    <template v-for="(item, index) in calculatedItems">
      <div
        ref="item"
        :key="index"
        tabindex="0"
        role="button"
        :class="{
          'bg-blue-600 text-white': item === selectedItem,
          'mb-4': index === calculatedItems.length - 1,
        }"
        class="snap-end text-center h-6 px-4 box-content leading-normal mx-5 rounded-sm py-1.5"
        @click.stop.prevent="select(item)"
        @keypress.stop.prevent="select(item)"
      >
        {{ item }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    infinite: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    preselectedItem: {
      type: String,
      required: true,
      default: () => '',
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    height: {
      type: Number,
      required: false,
      default: () => 4,
    },
  },
  data() {
    return {
      calculatedItems: this.generateDefaultItems(),
      columnHeight: 0,
      itemHeight: 0,
      selectedItem: this.preselectedItem,
      scrollableTop: false,
      scrollableBottom: false,
    };
  },
  computed: {
    language() {
      return this.$store.state.language.lang;
    },
  },
  mounted() {
    this.itemHeight = 42;
    this.columnHeight = this.height * this.itemHeight;
    this.$el.style.maxHeight = `${this.columnHeight}px`;
    this.select(this.selectedItem);
  },
  methods: {
    select(item) {
      // eslint-disable-next-line
      this.selectedItem = item;
      this.$emit('selectionChanged', this.selectedItem);
      this.$nextTick(() => {
        const selectedElement = document.querySelector(
          '.time-picker-column > div.bg-blue-600'
        );
        if (selectedElement) {
          const container = this.$el;
          const scrollTop =
            selectedElement.offsetTop - container.offsetTop - 10;
          container.scrollTo({
            top: Math.max(0, scrollTop),
            behavior: 'instant',
          });
        }
      });
    },
    generateDefaultItems() {
      if (this.infinite) {
        return [...this.items, ...this.items, ...this.items];
      }
      return this.items;
    },
  },
};
</script>

<style lang="scss">
.time-picker-column {
  &::-webkit-scrollbar {
    @apply hidden;
  }
  @apply overflow-y-scroll relative;
}
</style>
